import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Apollo } from "apollo-angular";
import {
  HttpLink
} from "apollo-angular-link-http";
import ApolloClient, {
  ApolloQueryResult,
  MutationOptions,
  QueryOptions
} from "apollo-client";
import { FetchResult } from "apollo-link";
import gql, {
  disableFragmentWarnings
} from "graphql-tag";
import * as jp from "jsonpath";
import * as lda from "lodash/array";
import {
  BehaviorSubject,
  Observable,
  Subject
} from "rxjs";

import { AppConfig } from "../../app.config";
import * as fromContext from "../../context/context";
import * as fromDimension from "../../context/dimension.reducers";
import {
  ReferenceSelector,
  toReferenceSelectors
} from "../../context/reference-selector.enum";
import * as fromReference from "../../context/reference.reducers";
import * as fromTemporal from "../../context/temporal.reducers";
import * as UserActions from "../../context/user.actions";
import {
  MutationType
} from "../../dashboard/components/config/mutation-type.enum";
import {
  SelectorConfig
} from "../../dashboard/components/config/selector-config";
import {
  ReferenceInput
} from "../../model/reference.model";
import {
  EnumValues
} from "../../util/enum-values";
import {
  LOCATION,
  REGION
} from "../../util/graphql-tags";
import * as constants from "../../util/string-constants";
import { isAuthenticated } from "../auth/auth-utils";
import {
  AuthenticationService
} from "../auth/authentication.service";
import { Logger } from "../logging/logger";
import {
  LoggingService
} from "../logging/logging.service";
import {
  createApolloClient
} from "./apollo-client";
import {
  DataRegistration
} from "./data-registration.model";
import {
  DataServiceEventType
} from "./data-service-event-type.enum";
import {
  DataServiceEvent
} from "./i-data-service-event";
import { IQuery } from "./i-query";
import { DataResult } from "./DataResult";

const QUERY_PLACEHOLDER: string = "{{{QUERY-PLACEHOLDER}}}";
const QUERY_CONFIGURATION_QUERYWRAPPERS = gql`
  query getConfigurationWrapper {
    Configuration {
      QueryWrappers {
        id
        guid
        query
        path
        priority
        returnType
        requiredSelectors
      }
    }
  }
`;
const PATH_TO_QUERY_CONFIGURATION_QUERYWRAPPERS_PAYLOAD: string = "$..QueryWrappers[*]";
const LOG_NAMESPACE: string = "services.data";

const _REFERENCE_CONTEXT_KEYS = [
  constants.LOCATION_ID,
  constants.REGION_ID,
  constants.LOCATION_TYPE_ID,
  constants.ENTITY_ID,
  constants.PARTNER_ID,
  constants.PERSON_ID,
  constants.ACTIVE_SNAPSHOT
];

@Injectable()
export class DataService {

  private _refreshingRegistrations: any = new Set();
  private _initialized = false;
  private _mutationTypes = {};
  private _referenceContextObserver$: Observable<fromReference.ReferenceContext>;
  private _temporalContextObserver$: Observable<fromTemporal.TemporalContext>;
  private _dimensionContextObserver$: Observable<fromDimension.DimensionContext>;

  private _referenceContext: fromReference.ReferenceContext;
  private _temporalContext: fromTemporal.TemporalContext;
  private _dimensionContext: fromDimension.DimensionContext;
  private _changedReferenceContextData: string[];

  private _registrations: { [id: string]: DataRegistration } = {};

  private _queryWrappers: { [id: string]: { [id: string]: any }[] } = {};
  private _logger: Logger;
  private _dataServiceEvents$ = new Subject<DataServiceEvent>();
  private _initializing: boolean = true;

  constructor(
      private _store: Store<fromContext.Context>,
      private _apollo: Apollo,
      httpLink: HttpLink,
      private _appConfig: AppConfig,
      loggingService: LoggingService,
      private _authenticationService: AuthenticationService) {

    // This disables the annoying fragment warnings we know aren't applicable to the way we use GraphQL queries right now.
    disableFragmentWarnings();

    this._apollo = createApolloClient(_apollo, httpLink);

    this._logger = new Logger(LOG_NAMESPACE, loggingService);
    this._loadQueryWrappers();
    this._initializeMutationRefresh();
    this._subscribeToReferenceContext();
    this._subscribeToTemporalContext();
    this._subscribeToDimensionContext();
    this._initializing = false;
  }

  public DataServiceEvent$(): Observable<DataServiceEvent> {
    return this._dataServiceEvents$.asObservable();
  }

  // TODO Handle situations where a reference is on a combination of parameters
  // like Region AND Location Type ID.
  public get ActiveReferenceInput(): ReferenceInput {
    if (this._referenceContext.locationID && this._referenceContext.locationID.length > 0) {
      return {
        type: LOCATION,
        id: this._referenceContext.locationID[0]
      };
    } else if (this._referenceContext.regionID && this._referenceContext.regionID.length > 0) {
      return {
        type: REGION,
        id: "0," + this._referenceContext.regionID[0]
      };
    } else {
      return null;
    }
  }

  public registerForData(registration: DataRegistration) {
    this._logger.info("Data Service registered for id '" + registration.requestorID  + "'");
    this._logger.debug("Data Service registered for id '" + registration.requestorID + "'" , {registrationObj: registration});
    this._registrations[registration.ID] = registration;
    this._updateMutationRefresh(registration);

    registration.Subscription = new BehaviorSubject<DataResult>({data: null, error: null});
    this._processUpdate(registration);
    // Keep needing this when debuggin so leaving them commented out rather than deleting them.
    // console.log("Added data registration for registration ID: " + registration.requestorID);
    return registration.Subscription;
  }

  public unRegisterForData(registration: DataRegistration) {
    this._logger.info("Data Service unRegistered for id '" + registration.requestorID  + "'");
    if (!!registration.Subscription && !!registration.Subscription.closed) {
      registration.Subscription.unsubscribe();
    }
    this._cleanupRegistration(registration.ID);
    // Keep needing this when debuggin so leaving them commented out rather than deleting them.
    // console.log("Removed data registration for registration ID: " + registration.requestorID);
  }

  public mutate(id: string, gqlQuery: string, variables: {[key: string]: any} = null): Promise<FetchResult> {

    const mopts: MutationOptions = {
      mutation: gql(gqlQuery)
    };
    if (variables) {
      mopts.variables = variables;
    }

    this._logger.info("Executing Mutation for id '" + id + "' with options" , mopts);
    return this._apollo.mutate(mopts).toPromise();

  }

  private __defaultMutationTypes(): MutationType[] {
    return (Object.values(MutationType).filter((x) => x !== MutationType.DemoReset)) as MutationType[];
  }


  public refreshAfterMutation(...mTypes: MutationType[]): void {
    let registrationIDsToRefresh;

    if (JSON.stringify([MutationType.DemoReset]) === JSON.stringify(mTypes)) {
      console.log("DEMO RESET.  REFRESHING ALL MUTATION AWARE REGISTRATIONS...");
      mTypes = this.__defaultMutationTypes();
    }

    if (mTypes.length === 0) {
      registrationIDsToRefresh = Object.keys(this._registrations);
    } else {
      registrationIDsToRefresh = mTypes.reduce((list: string[], curr) => {
        const typeToRefresh = this._mutationTypes[curr];
        return lda.uniq(list.concat(typeToRefresh));
      }, []);
    }
    if (registrationIDsToRefresh == null || registrationIDsToRefresh.length === 0) {
      return;
    }
    const toRefresh: DataRegistration[] = [];
    for (const rID of registrationIDsToRefresh) {
      const registration = this._registrations[rID];
      if (registration == null) {
        continue;
      }
      this._buildRelevantQueryByContext(registration, this._referenceContext);
      if (registration.resolveSelectorsIntoQuery(registration.currentQuery, this._temporalContext, this._dimensionContext, this._referenceContext)) {
        toRefresh.push(registration);
      }
    }
    for (const registration of toRefresh) {
      const queryHeader: { [id: string]: any } = this._queryHeaderFromContext(registration, this._referenceContext);
      if (queryHeader) {
        this._buildExecutableQuery(queryHeader[constants.QUERY], registration);
        this._executeQuery(registration, queryHeader[constants.PATH]);
      }
    }
  }

  public runQuery(id: string, queryOptions: QueryOptions): Promise<ApolloQueryResult<any>> {
    const queryAndVariables = {query: queryOptions.query.loc.source.body};
    if (queryOptions.variables) {
        queryAndVariables["variables"] = queryOptions.variables;
    }
    this._logger.info("Executing Query for id '" + id + "' with query options" , queryAndVariables);
    return this._apollo.query(queryOptions).toPromise();
  }

  private _updateRegistrationsRefreshStatus() {
    if (this._refreshingRegistrations.size === 0) {
      const event: DataServiceEvent = {
        type: DataServiceEventType.IDLE
      };
      this._dataServiceEvents$.next(event);
    }
  }

  private _updateMutationRefresh(registration: DataRegistration): void {
    const mTypes = registration.refreshOnMutationOfTypes;
    mTypes.forEach((mType) => {
      this._mutationTypes[mType].push(registration.ID);
    });
  }

  private _cleanupRegistration(registrationID: string): void {
    delete this._registrations[registrationID];
    Object.keys(this._mutationTypes).forEach((key) => {
      this._mutationTypes[key] = lda.without(this._mutationTypes[key], registrationID);
    });
  }

  private _getRelevantQueryWrapper(
    registration: DataRegistration, queryWrappers: any,
    context: fromReference.ReferenceContext, referenceSelectors: ReferenceSelector[]): { [id: string]: string } {

    this._logger.info("Getting Relevant query wrappers for id '" + registration.requestorID  + "'");
    for (const wrapper of queryWrappers) {
      const requiredSelectors: string[] = wrapper[constants.REQUIRED_SELECTORS];

      if (requiredSelectors.length !== referenceSelectors.length) {
        continue;
      }

      let matches: boolean = true;
      for (const selector of referenceSelectors) {
        if (requiredSelectors.indexOf(selector) === -1) {
          matches = false;
          break;
        }
      }

      if (matches) {
        this._logger.debug("Got the relevant query wrappers for id '" + registration.requestorID  + "'", { wrapperObj : wrapper});
        return wrapper;
      }

    }
    const errorMsg: string = "Could not find matching query wrapper for the context selected!";
    this._errorHandler(errorMsg);
    return null;
  }

  private _queryHeaderFromContext(registration: DataRegistration, context: fromReference.ReferenceContext, additionalReferenceSelectors: string[] = []): { [id: string]: string } {

    const returnType: string = registration.currentQuery.returnType;
    if (!this._queryWrappers[returnType]) {
      const errorMsg: string = "Requested return type " + returnType + " has no registered query wrappers.";
      this._errorHandler(errorMsg);
    }
    const referenceSelectors: ReferenceSelector[] = [...registration.currentQuery.selectors.reference, ...toReferenceSelectors(additionalReferenceSelectors)];
    const queryWrappers = this._queryWrappers[returnType];
    return this._getRelevantQueryWrapper(registration, queryWrappers, context, referenceSelectors);
  }

  private _subscribeToTemporalContext() {
    this._logger.info("Subscribing To Temporal Context");
    this._temporalContextObserver$ = this._store.select(constants.CONTEXT_TEMPORAL);
    this._temporalContextObserver$.subscribe((newTemporalContext: fromTemporal.TemporalContext) => {

      this._logger.debug("new Temporal context " , { context: newTemporalContext});
      if (this._initialized) {
        const currentTemporalContext: fromTemporal.TemporalContext = this._temporalContext;
        this._temporalContext = newTemporalContext;
        this._processUpdate(null, null, this._changedTemporalSelectors(newTemporalContext, currentTemporalContext), null);
      }
    });
  }

  private _initializeMutationRefresh() {
    // Create dictionary of each value in the MutationType enum
    // and create an empty array for each.
    // The GUID of data registrations which want to be updated when that mutation occurs
    // will be placed in this list.
    const enumValues = new EnumValues(MutationType);
    this._mutationTypes = enumValues.Values.reduce((dict, curr) => {
      dict[+curr] = [];
      return dict;
    }, {});
  }

  private _subscribeToReferenceContext() {
    this._logger.info("Subscribing To Reference Context");
    this._referenceContextObserver$ = this._store.select(constants.CONTEXT_REFERENCE);
    this._referenceContextObserver$.subscribe((newData: fromReference.ReferenceContext) => {
      this._logger.info("new Reference context ", newData );
      this._changedReferenceContextData = this._changedReferenceSelectors(newData);
      this._logger.info("Changed Reference context ", this._initializing, this._changedReferenceContextData );
      this._referenceContext = newData;
      if (this._changedReferenceContextData.length > 0) {
        this._processUpdate(null, this._changedReferenceContextData, null);
      }
    });
  }

  private _subscribeToDimensionContext() {
    this._logger.info("Subscribing To Dimension Context");
    this._dimensionContextObserver$ = this._store.select(constants.CONTEXT_DIMENSION);
    this._dimensionContextObserver$.subscribe((newDimensionContext: fromDimension.DimensionContext) => {
      // FOR SOME REASON THE CONTEXT OLD AND NEW HAS THE SAME CONTENT!?!?!
      const currentDimensionContext = this._dimensionContext;
      this._dimensionContext = newDimensionContext;
      this._logger.debug("new Dimension context " , {context: newDimensionContext});
      const changedDimensions = this._changedDimensionSelectors(currentDimensionContext, newDimensionContext);
      if (changedDimensions.length > 0) {
        this._processUpdate(null, null, null, changedDimensions);
      }
    });
  }

  private _changedDimensionSelectors(newContext: fromDimension.DimensionContext, currentContext: fromDimension.DimensionContext): string[] {
    if (this._initializing) {
      return [];
    }
    const ret = this._diffedKeys(newContext, currentContext);
    return ret;
  }

  private _objectKeys(obj: any): string[] {
    if (null == obj) {
      return [];
    }
    return Object.keys(obj);
  }

  private _diffedKeys(a: any, b: any): string[] {

    const ret: string[] = [];
    this._checkKeysForDiff(a, b, ret);
    this._checkKeysForDiff(b, a, ret);
    return lda.uniq(ret);

  }

  private _checkKeysForDiff(a: any, b: any, outputDiffKeyList: string[]): void {
    const keysFromA: string[] = this._objectKeys(a);
    const noB = null == b;

    if (noB) {
      outputDiffKeyList.push(...keysFromA);
    } else {
      for (const keyOfA of keysFromA) {
        const diff = this._isDifferent(a[keyOfA], b[keyOfA]);
        if (!(keyOfA in b) || diff) {
          // This key has changed.
          outputDiffKeyList.push(keyOfA);
        }
      }
    }
  }

  private _changedTemporalSelectors(newContext: fromTemporal.TemporalContext, currentContext: fromTemporal.TemporalContext): string[] {
    const ret = this._diffedKeys(newContext, currentContext);
    return ret;
  }

  private _changedReferenceSelectors(newData: fromReference.ReferenceContext): string[] {
    const newDataProperties = _REFERENCE_CONTEXT_KEYS;
    if (this._initializing) {
      return [];
    } else if (!this._referenceContext) {
      return newDataProperties;
    }

    const ret: string[] = [];
    for (const property of newDataProperties) {
      if (this._isDifferent(newData[property], this._referenceContext[property])) {
        ret.push(property);
      }
    }
    return ret;
  }

  private _processUpdate(
    newRegistration?: DataRegistration,
    changedReferenceSelectors?: string[],
    changedTemporalSelectors?: string[],
    changedDimensionSelectors?: string[]) {

    if (isAuthenticated().isTokenExpired) {
      this._store.dispatch(new UserActions.AuthenticationRequired());
      return;
    }
    const registrationsToRefresh = this._registrationsToRefresh(newRegistration, changedReferenceSelectors,
                                                                changedTemporalSelectors, changedDimensionSelectors);

    let refreshingDataUpdate = false;
    for (const registration of registrationsToRefresh) {
      registration.statusSubject.next(DataServiceEventType.BUSY);
      const queryHeader: { [id: string]: any } = this._queryHeaderFromContext(registration, this._referenceContext);
      if (queryHeader) {
        if (!refreshingDataUpdate) {
          const event: DataServiceEvent = {
            type: DataServiceEventType.BUSY
          };
          this._dataServiceEvents$.next(event);
          refreshingDataUpdate = true;
        }
        this._refreshingRegistrations.add(registration.ID);
        this._buildExecutableQuery(queryHeader[constants.QUERY], registration);
        this._executeQuery(registration, queryHeader[constants.PATH]);
      }
    }
  }

  public async runQueryOnceFromRegistration(registration: DataRegistration, queryVariables?: any): Promise<DataResult> {
    this._buildRelevantQueryByContext(registration, this._referenceContext);
    let additionalReferenceSelectors;
    if (null != queryVariables) {
      additionalReferenceSelectors = Object.keys(queryVariables);
    }
    if (!registration.resolveSelectorsIntoQuery(registration.currentQuery, this._temporalContext, this._dimensionContext, this._referenceContext)) {
      return;
    }
    const queryHeader: { [id: string]: any } = this._queryHeaderFromContext(registration, this._referenceContext, additionalReferenceSelectors);

    this._buildExecutableQuery(queryHeader[constants.QUERY], registration);
    return this._executeQuery(registration, queryHeader[constants.PATH], queryVariables);
  }

  private _buildExecutableQuery(queryHeader: string, registration: DataRegistration): void {
    this._logger.info("Building Executable Query for id '" + registration.requestorID + "'");
    if (null == registration.currentQuery.query) {
      this._logger.debug(`Executable Query for id ${registration.requestorID} is set to null so no query will be run.  This could very well be intentional but if it is NOT what you expected then check your query confifguration...`);
      registration.executableQuery = null;
      return;
    }
    // GraphQL doesn't like fragment names that start with numbers or
    // contain dashes, so we pre-pend an underscore and remove the dashes.
    const fragmentName = `${"_" + registration.ID.replace(/-/g, "")}_fields`;
    const queryFragment = `fragment ${fragmentName} on ${registration.currentQuery.resolvedQuery}`;
    const executableQuery = `
      ${queryHeader.replace(QUERY_PLACEHOLDER, "..." + fragmentName)}
      ${queryFragment}
    `;
    this._logger.debug("Executable Query for id '" + registration.requestorID + "'", { Query: executableQuery});
    registration.executableQuery = executableQuery;
  }

  private async _executeQuery(registration: DataRegistration, path: string, queryVariables?: any): Promise<DataResult> {
    let dataResult = await this._executeQueryGetResult(registration, path, queryVariables);
    if (null == dataResult) {
      this._logger.error(`Invalid: dataResult should not be null.`);
      return;
    }
    if (null != dataResult.data) {
      if (null != registration.servicedPathToData) {
        dataResult.data = jp.query(dataResult.data, registration.servicedPathToData);
      }
    }

    // Parse stringValues
    if (null != dataResult.data) {
      jp.apply(dataResult.data, '$..SeriesData[*]', (seriesDatum: any) => {
        if (null != seriesDatum.SeriesDefinition && seriesDatum.SeriesDefinition.dataType === "JSON" ) {
          seriesDatum.stringValues = seriesDatum.stringValues.map((stringValue: any) => JSON.parse(stringValue));
        }
        return seriesDatum;
      });
    }

    if (null != registration.Subscription) {
      registration.Subscription.next(dataResult);
      registration.statusSubject.next(DataServiceEventType.IDLE);
    }
    return dataResult;
  }

  private async _executeQueryGetResult(registration: DataRegistration, path: string, queryVariables: any = {}): Promise<DataResult> {

    if (null == registration.executableQuery) {
      this._logger.debug(`Registration ${registration.requestorID} has no executable query.  Returning null so downstream consumers can go into "no data" mode...`);
      try {
        return {data: null, error: null};
      } catch (err) {
        this._logger.error(
          `Error dispatching null data object to registration ${registration.requestorID}.`,
          { Query:  "None", Error: err}
        );
        return {data: null, error: null};
      } finally {
        this._removeRefreshingRegistration(registration.ID);
        return {data: null, error: null};
      }
    }

    const variables: { [id: string]: string[] } = {};
    for (const selector of registration.currentQuery.selectors.reference) {
      variables[selector] = this._referenceContext[selector];
    }
    let queryOptions: QueryOptions;
    // console.log(registration.executableQuery);
    try {
      queryOptions = {
        query: gql`${registration.executableQuery}`
      };
    } catch (e) {
      this._removeRefreshingRegistration(registration.ID);
      this._logger.error(`Syntax Error in Component ${registration.requestorID} Query` ,
      { Query:  registration.executableQuery, Variables: variables, Error: e}
      );
      return {data: null, error: null};
    }
    if (variables) {
      queryOptions.variables = {...variables, ...queryVariables};
    }
    this._logger.info("Executing Query for id '" + registration.requestorID + "' with query options" ,
    { Query:  registration.executableQuery, Variables: variables});

    try {
      const response: ApolloQueryResult<any> = await (this._apollo.query(queryOptions).toPromise());
      const unwrapped = this._unwrapResponse(response, path, registration.requestorID);
      this._logger.debug(`Response for [${registration.requestorID}]: `, {resultObj: response});
      this._removeRefreshingRegistration(registration.ID);
      return {data: unwrapped, error: null};
    } catch (err) {
      this._removeRefreshingRegistration(registration.ID);

      const error = {
        message: `Error executing query for [${registration.requestorID}]: `,
        Query: registration.executableQuery, Variables: variables, Error: err
      }

      this._logger.error(
        error.message, { Query: error.Query, Variables: error.Variables, Error: error.Error }
      );
      return {data: null, error};
    }
  }

  private _removeRefreshingRegistration(registrationID: string) {
    this._refreshingRegistrations.delete(registrationID);
    this._updateRegistrationsRefreshStatus();
  }

  private _referenceContextRequiresRefresh(
    registration: DataRegistration,
    changedReferenceSelectors?: string[],
    isNewRegistration: boolean = false
  ): boolean {
    if (null == this._referenceContext || null == registration.currentQuery || (null == changedReferenceSelectors && !isNewRegistration)) {
      // Without at least SOME reference data we can't re-run this query.
      // Similarly, if we have no changed selectors and this is NOT a new registration then we
      // don't need to do anything.
      return false;
    }

    for (const referenceSelector of registration.currentQuery.selectors.reference) {
      if (this._referenceContext[referenceSelector].length > 0 || registration.currentQuery.selectors.allowEmptyContext) {
        // As long as we have at least ONE reference selector available
        // this query can be run, provided this selector has changed
        // or the list of changed values is not provided.
        const shouldRefresh: boolean = null != changedReferenceSelectors || changedReferenceSelectors.indexOf(referenceSelector) > -1;
        if (shouldRefresh) {
          // Return if we've hit something that triggers true
          // otherwise let the loop keep going.
          return shouldRefresh;
        }
      }
    }

    return false;
  }

  private _getSelectors(registration: DataRegistration, selectorType: string): SelectorConfig[] {
    if (
      null == registration ||
      null == registration.currentQuery ||
      null == registration.currentQuery.selectors ||
      null == registration.currentQuery.selectors[selectorType]
    ) {
      return [];
    } else {
      return registration.currentQuery.selectors[selectorType];
    }
  }

  private _contextPartRequiresRefresh(registration: DataRegistration, contextSelector: string, changedProperties?: string[]): boolean {
    if (null == changedProperties) {
      return false;
    }
    const toCheck: SelectorConfig[] = this._getSelectors(registration, contextSelector);
    for (const selector of toCheck) {
      // TODO - This is way too simplistic but this is just to get us going...
      // For now, split the path on . and check for any part of the path matching a key.
      const pathElements: string[] = selector.path.split(constants.DOT);
      for (const pathElement of pathElements) {
        if (changedProperties.findIndex((curr: string) => curr === pathElement) !== -1) {
          this._logger.debug(`PATH ${pathElement} in ${JSON.stringify(changedProperties)} means ${contextSelector} requires refresh.`);
          return true;
        }
      }
    }
    // tslint:disable-next-line:max-line-length
    this._logger.debug(`Properties ${JSON.stringify(changedProperties)} did not cause a refresh trigger from any selector for data registration ${registration.ID}`);
    return false;
  }

  // Todo, need to actually decide if this needs refreshing using similar logic to _dimensionContextRequiresRefresh!
  private _temporalContextRequiresRefresh(registration: DataRegistration, changedTemporalProperties?: string[]): boolean {
    return this._contextPartRequiresRefresh(registration, fromContext.TemporalContextKeys.CONTEXT_KEY, changedTemporalProperties);
  }

  private  _dimensionContextRequiresRefresh(registration: DataRegistration, changedDimensionProperties?: string[]): boolean {
    return this._contextPartRequiresRefresh(registration, fromContext.DimensionContextKeys.CONTEXT_KEY, changedDimensionProperties);
  }

  private _buildRelevantQueryByContext(registration: DataRegistration, referenceContext?: fromReference.ReferenceContext) {
    registration.hasQueryChanged = false;
    if (registration.queries.length === 1 ) {
      if (!registration.currentQuery) {
        registration.hasQueryChanged = true;
        registration.currentQuery = registration.queries[registration.queries.length - 1];
      }
      return;
    }

    const activeReferenceContext = _REFERENCE_CONTEXT_KEYS.slice().filter((key) => referenceContext[key] && referenceContext[key].length > 0);
    const _previousQuery: IQuery = registration.currentQuery;

    if (activeReferenceContext.length > 0) {
      registration.currentQuery = undefined;
      for (const query of registration.queries) {
        let matchesWithSelectors = true;
        for (const sel of query.selectors.reference) {
          if (activeReferenceContext.indexOf(sel) === -1) {
            matchesWithSelectors = false;
            break;
          }
        }

        if (matchesWithSelectors) {
          registration.currentQuery = query;
          // Comparing previous query with current selected query
          if (!_previousQuery || _previousQuery.UUID !== query.UUID) {
            registration.hasQueryChanged = true;
          }
          break;
        }
      }
    } else {
      // Looping in reverse assuming empty context will be the least prioritized one,
      // and can get to it quickly.
      const queriesLength: number = registration.queries.length;
      for (let i = queriesLength - 1; i >= 0; i--) {
        const query = registration.queries[i];
        if (query.selectors.reference.length === 0) {
          if (!_previousQuery || _previousQuery.UUID !== query.UUID) {
            registration.currentQuery = query;
            registration.hasQueryChanged = true;
          }
          break;
        }
      }
    }
  }

  private _registrationsToRefresh(
    newRegistration?: DataRegistration,
    changedReferenceSelectors?: string[],
    changedTemporalSelectors?: string[],
    changedDimensionSelectors?: string[]): DataRegistration[] {

    const registrationsToRefresh: DataRegistration[] = [];
    // tslint:disable-next-line:max-line-length
    const isNewRegistration = null != newRegistration;
    // tslint:disable-next-line: max-line-length
    const registrationsToIterate: DataRegistration[] = isNewRegistration ? [newRegistration] : Object.keys(this._registrations).map((key) => this._registrations[key]);
    for (const registration of registrationsToIterate) {
      this._buildRelevantQueryByContext(registration, this._referenceContext);
      if (registration.hasQueryChanged || this._referenceContextRequiresRefresh(registration, changedReferenceSelectors, isNewRegistration)
                                       || this._dimensionContextRequiresRefresh(registration, changedDimensionSelectors)
                                       || this._temporalContextRequiresRefresh(registration, changedTemporalSelectors)) {
        // OK, passed the first test.  Now resolve all the temporal selectors
        // and see if we are still good to go.
        this._logger.debug(`Registration ${registration.ID} requires refreshing based on context changes.`);
        if (
            registration.resolveSelectorsIntoQuery(
              registration.currentQuery,
              this._temporalContext,
              this._dimensionContext,
              this._referenceContext)) {
          registrationsToRefresh.push(registration);
        }
      }
    }
    // This should now contain all registrations that MIGHT need to be
    // refreshed.
    this._logger.debug("Data Registrations to be refereshed:" , { RegistrationsToRefresh : registrationsToRefresh});
    return registrationsToRefresh;
  }

  private _unwrapResponse(response: ApolloQueryResult<any>, path: string, id: string) {
    if (response !== null) {
      if (response[constants.IS_ROOT_QUERY] === true) {
        this._logger.debug("Unwrapping the response of root query for id '" + id + "'", { UnwrappedResponse : response[constants.DATA] });
        return response.data;
      } else {
        const unWrappedResponse = jp.query(response[constants.DATA], path);
        this._logger.debug("Unwrapping the response for id '" + id + "'" , { UnwrappedResponse : unWrappedResponse});
        return unWrappedResponse;
      }
    }
  }

  private async _loadQueryWrappers() {
    this._logger.info("Loading Query Wrappers");
    const result = await (this._apollo.query({ query: QUERY_CONFIGURATION_QUERYWRAPPERS }).toPromise());
    this._logger.debug("Got Query Wrappers " , { Wrappers : result });
    // this._logService.debug("Building query wrapper for ", { data: result});
    this._buildQueryWrappers(result);
    this._initialized = true;
  }

  private _buildQueryWrappers(result: ApolloQueryResult<any>) {
    const availableQueryWrappers = this._unwrapResponse(result, PATH_TO_QUERY_CONFIGURATION_QUERYWRAPPERS_PAYLOAD, "QueryWrapper");
    for (const wrapper of availableQueryWrappers) {
      const wrappers = this._queryWrappers[wrapper[constants.RETURN_TYPE]];
      if (wrappers) {
        wrappers.push(wrapper);
      } else {
        this._queryWrappers[wrapper[constants.RETURN_TYPE]] = [wrapper];
      }
    }

    for (const key of Object.keys(this._queryWrappers)) {
      this._queryWrappers[key] = this._queryWrappers[key].sort((a, b) => a[constants.PRIORITY] - b[constants.PRIORITY]);
    }
  }

  private _isDifferent(a1: any, a2: any): boolean {

    // Short circuit nullness checks.
    if (null == a1 && null == a2) {
      return false;
    } else if ((null != a1 && null == a2) || (null != a2 && null == a1)) {
      return true;
    }

    if (a1 instanceof Array && a2 instanceof Array) {
      const newItems = a1.filter((item) => a2.indexOf(item) < 0);
      const missingItems = a2.filter((item) => a1.indexOf(item) < 0);
      return newItems.length > 0 || missingItems.length > 0;
    } else if (typeof a1 === constants.STRING || typeof a1 === constants.NUMBER || typeof a1 === constants.BOOLEAN) {
      return a1 !== a2;
    } else {
      const newObjectProperties = Object.getOwnPropertyNames(a1);
      for (const prop of newObjectProperties) {
        if (this._isDifferent(a1[prop], a2[prop])) {
          return true;
        }
      }
      return false;
    }
  }

  private _errorHandler(errorMessage: string) {
    throw new Error(errorMessage);
  }
}
